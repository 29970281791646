<template>
        <el-dialog title="Activitati angajati" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                <el-col :span='8'>
                    <el-form-item label='Angajat' >
                        <el-select class='full-width' v-model='selectedObject.IdAngajat' filterable>
                            <el-option  v-for='item in Info.angajati' :key="'angajat' + item.Id" :label='item.Nume' :value='item.Id' :disabled="item.Disabled"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col> 
                <el-col :span='8'>
                    <el-form-item label='Activitate' >
                        <el-select class='full-width' v-model='selectedObject.IdActivitate' filterable>
                            <el-option-group
                            v-for="group in Info.activitati"
                            :key="group.label"
                            :label="group.label">
                                <el-option
                                    v-for="item in group.options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-option-group>
                        </el-select>
                    </el-form-item>
                </el-col> 
                <el-col :span='8'>
                    <el-form-item label='Numar ore' >
                        <el-input-number class='full-width' v-model='selectedObject.NumarOre' ></el-input-number>
                    </el-form-item>
                </el-col> 
                       

            </el-row>
        </el-form>

        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "angajati-activitati_dialog",
        extends: BasePage,
        computed:{
            
        },
        watch:{

        },
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObjectTemplate: {IdAngajat: '' , IdActivitate:'', NumarOre:1},    
                selectedObject:{},              
                Info:{ angajati:[], activitati:[] },
                                   
                rules: {
                    IdAngajat: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdActivitate: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    NumarOre: [ { required: true, message: 'Campul este obligatoriu' } ],                    
                },
                iduri_functii_excluse:['1','2','3','7']
            }
        },
        methods: {
            show_me: async function( id ) {
                this.selectedObject = JSON.parse(JSON.stringify(this.selectedObjectTemplate));
                this.showPopup      = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("angajati_activitati/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response            = await this.post("angajati_activitati/get_info_for_dialog" );
                this.Info.angajati      = response.Angajati
                this.Info.activitati    = response.Activitati
                this.angajat_disabled()
            },
            save: async function() {
               
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("angajati_activitati/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            },
            angajat_disabled(){
                this.Info.angajati.forEach(a => {
                    a.Disabled = false
                    if(iduri_functii_excluse.includes(a.IdFunctie))
                        a.Disabled=true
                })
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>